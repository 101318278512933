import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Icon } from '@qga/roo-ui/components';
import { useDataLayer } from 'hooks/useDataLayer';
import { useBreakpoints } from 'hooks/useBreakpoints';
import SelectMenu from './components/SelectMenu';
import type { Option } from '../types';
import Filter from './components/Filter';
import { Menu, FullScreenMenu, Backdrop, StyledText } from './Select.style';

interface Props {
  value?: string;
  options: Array<Option>;
  placeholder?: string;
  prefix?: string;
  disabled?: boolean;
  ellipsis?: boolean;
  onSelectedChange: (value: string) => void;
  setShowMenu: (value: boolean) => void;
  showMenu: boolean;
  alignItems: string;
  menuAlign: string;
  height: string;
}

const Select = ({
  options,
  value,
  placeholder,
  prefix,
  onSelectedChange = () => null,
  disabled = false,
  ellipsis = false,
  setShowMenu,
  showMenu = false,
  ...props
}: Props) => {
  const { emitInteractionEvent } = useDataLayer();
  const selected = useMemo(() => options.find((option) => option.value === value), [options, value]);
  const [active, setActive] = useState(false);
  const refMenu = useRef<HTMLInputElement>(null);
  const refInput = useRef<HTMLButtonElement>(null);

  const { isLessThanBreakpoint } = useBreakpoints();
  const isMobile = isLessThanBreakpoint(0);

  const onInputClick = () => {
    setShowMenu(!showMenu);
    emitInteractionEvent({ type: 'Availability Calendar', value: 'Room filter clicked' });
  };

  const onCloseClick = () => {
    setShowMenu(false);
  };

  const onMenuItemClick = (event: React.MouseEvent, option: Option) => {
    event.preventDefault();
    setShowMenu(false);
    onSelectedChange(option.value);
  };

  useEffect(() => {
    setActive(!!selected?.value);
  }, [selected]);

  useEffect(() => {
    const handleClickOutside = (mouseEvent: MouseEvent) => {
      if (refInput.current && !refInput.current.contains(mouseEvent.target as Node)) {
        if (
          refMenu.current &&
          !refMenu.current.contains(mouseEvent.target as Node) &&
          !refInput.current.contains(mouseEvent.target as Node)
        ) {
          if (showMenu) setShowMenu(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refMenu, refInput, setShowMenu, showMenu]);

  const selectedText = selected ? (prefix ? `${prefix} ${selected.text}` : `${selected.text}`) : placeholder;
  const isActive = active || showMenu;

  return (
    <Box width="50%">
      <Filter
        data-testid="room-dropdown-select"
        onClick={onInputClick}
        ref={refInput}
        active={isActive}
        pl={5}
        pr={4}
        disabled={disabled}
        {...props}
      >
        <Box width={ellipsis ? '140px' : '0px'} flex="1 1 auto">
          <StyledText fontWeight="inherit" fontSize="inherit">
            {selectedText}
          </StyledText>
        </Box>
        <Box ml={2}>
          <Icon size={24} name={showMenu ? 'expandLess' : 'expandMore'} />
        </Box>
      </Filter>
      {showMenu && (
        <>
          {isMobile ? (
            <>
              <Backdrop />
              <FullScreenMenu data-testid="room-dropdown-select-fullscreen" position="absolute" ref={refMenu} mt={2} borderRadius="default">
                <SelectMenu
                  selected={selected}
                  options={options}
                  menuLabel={placeholder}
                  onMenuItemClick={onMenuItemClick}
                  onCloseClick={onCloseClick}
                />
              </FullScreenMenu>
            </>
          ) : (
            <Menu data-testid="room-menu" position="absolute" ref={refMenu} mt={2} mr={[2, 8, 12]} borderRadius="default" right="1px">
              <SelectMenu selected={selected} options={options} onMenuItemClick={onMenuItemClick} data-testid="select-menu" />
            </Menu>
          )}
        </>
      )}
    </Box>
  );
};

export default Select;

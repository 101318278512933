import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import noop from 'lodash/noop';
import { Wrapper } from '@qga/roo-ui/components';
import { Label, LabelText } from 'components/Label';
import { themeGet } from 'styled-system';
import { format } from 'lib/date';
import { FOCUS } from '../constants';
import InputWithAddon from 'components/InputWithAddon';

const DEFAULT_DATE_FORMAT = 'd MMM';

interface PhoneDateDisplayProps {
  range: { startDate?: Date; endDate?: Date };
  dateFormat?: string;
  handleFocusedInput: (value) => void;
  labelOptions: object;
  error: boolean;
  isOptional: boolean;
  display: string[];
}

interface DateInputProps {
  left: number;
  icon: string;
  borderRadius: string;
  border: number;
  value: string;
  onClick: () => void;
  onFocus: () => void;
  onKeyUp: (value) => void;
  error: boolean;
  suffix: string | string;
  readonly: boolean;
  inputMode: string;
}

const formatDate = (date, dateFormat) => {
  if (date) {
    return format(date, dateFormat);
  } else {
    return '';
  }
};

const DateInput = styled(InputWithAddon)<DateInputProps>`
  color: ${themeGet('colors.greys.steel')};

  &:focus {
    border-color: ${themeGet('colors.ui.linkFocus')};
  }
`;

const PhoneDateDisplay = ({
  range = { startDate: undefined, endDate: undefined },
  dateFormat = DEFAULT_DATE_FORMAT,
  labelOptions = {},
  handleFocusedInput = noop,
  error = false,
  isOptional = false,
  ...rest
}: PhoneDateDisplayProps) => {
  const handleFocus = useCallback(() => handleFocusedInput(FOCUS.START_DATE), [handleFocusedInput]);
  const onKeyUp = useCallback(
    ({ keyCode }) => {
      if (keyCode === 13) handleFocus();
    },
    [handleFocus],
  );

  const { startDate, endDate } = range;
  const dateInputValue = useMemo(
    () => (startDate || endDate ? `${formatDate(startDate, dateFormat)} - ${formatDate(endDate, dateFormat)}` : 'Select dates'),
    [startDate, endDate, dateFormat],
  );

  return (
    <Wrapper {...rest}>
      <Label data-testid="phone-date-display">
        <LabelText {...labelOptions} hidden>
          Check in and Check out dates
        </LabelText>
        <DateInput
          left={2}
          icon="calendar"
          borderRadius="default"
          border={2}
          value={dateInputValue}
          onClick={handleFocus}
          onFocus={handleFocus}
          onKeyUp={onKeyUp}
          error={error}
          suffix={isOptional ? 'Optional' : ''}
          readonly
          inputMode="none"
        />
      </Label>
    </Wrapper>
  );
};

export default PhoneDateDisplay;

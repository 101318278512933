import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import noop from 'lodash/noop';
import { Flex, Box, BoxProps } from '@qga/roo-ui/components';
import { Label, LabelText } from 'components/Label';
import { themeGet } from 'styled-system';
import { format } from 'lib/date';
import { FOCUS } from '../constants';
import InputWithAddon from 'components/InputWithAddon';

const DEFAULT_DATE_FORMAT = 'EEE d MMM, yyyy';

interface DateDisplayProps {
  range: { startDate?: Date; endDate?: Date };
  dateFormat?: string;
  focusedInput: string;
  handleFocusedInput: (value: string) => void;
  labelOptions: object;
  error: boolean;
  backgroundColor: string;
  isOptional: boolean;
  display: string[];
}

interface InputDividerProps extends BoxProps {
  isHighlighted: boolean;
}

const InputDivider = styled(Box)<InputDividerProps>`
  position: absolute;
  right: 0;
  bottom: ${(props) => (props.isHighlighted ? 0 : themeGet('space.2')(props))};
  top: ${(props) => (props.isHighlighted ? 0 : themeGet('space.2')(props))};
  border-right-width: ${(props) => (props.isHighlighted ? '2px' : '1px')};
  border-right-color: ${(props) =>
    props.isHighlighted ? themeGet('colors.brand.secondary')(props) : themeGet('colors.greys.alto')(props)};
  border-right-style: solid;
  width: 0px;
`;

InputDivider.defaultProps = {
  ...Box.defaultProps,
};

const DateDisplay = ({
  range = { startDate: undefined, endDate: undefined },
  dateFormat = DEFAULT_DATE_FORMAT,
  focusedInput = FOCUS.NONE,
  handleFocusedInput = noop,
  labelOptions = {},
  error = false,
  backgroundColor = 'white',
  isOptional = false,
  ...rest
}: DateDisplayProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusedInput === FOCUS.DATE) inputRef.current?.focus();
    else if (focusedInput === FOCUS.START_DATE) handleFocusedInput(FOCUS.DATE);
  }, [focusedInput, handleFocusedInput]);

  const handleFocusDate = useCallback(() => handleFocusedInput(FOCUS.DATE), [handleFocusedInput]);

  const { startDate, endDate } = range;
  const dateFocused = useMemo(() => focusedInput === FOCUS.START_DATE, [focusedInput]);

  const startDateMessage = startDate ? format(startDate, dateFormat) : '';
  const endDateMessage = endDate ? format(endDate, dateFormat) : '';

  return (
    <Flex {...rest} backgroundColor={backgroundColor}>
      <Box flex="1 1 auto">
        <Label>
          <LabelText {...labelOptions} color="greys.charcoal" fontWeight="normal" fontSize="sm">
            When?
          </LabelText>
          <Box position="relative">
            <InputWithAddon
              icon="calendar"
              ref={inputRef}
              placeholder="Select dates"
              value={startDate ? `${startDateMessage} - ${endDateMessage}` : ''}
              onFocus={handleFocusDate}
              isHighlighted={dateFocused}
              readonly
              data-testid="select-date-input"
              borderRadius="default"
              border={2}
              cursor="pointer"
              error={error}
              suffix={isOptional ? 'Optional' : ''}
            />
          </Box>
        </Label>
      </Box>
    </Flex>
  );
};

export default DateDisplay;

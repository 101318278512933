import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { mediaQuery } from 'lib/styledSystem';
import { themeGet } from 'styled-system';
import { Flex, Box, Text } from '@qga/roo-ui/components';
import { getIsMobileApp } from 'store/ui/uiSelectors';

const CalendarKeyWrapper = styled(Flex)`
  justify-content: center;
  width: 100%;
  z-index: ${themeGet('zIndices.mobileModal')};
  padding: ${themeGet('space.0')} ${themeGet('space.8')} ${themeGet('space.2')};
  margin-bottom: ${themeGet('space.2')};
  background-color: transparent;

  ${mediaQuery.minWidth.sm} {
    align-items: center;
    position: relative;
    margin-top: ${themeGet('space.4')};
    padding: ${themeGet('space.0')};
    background-color: none;
    height: 60px;
    width: auto;
  }

  ${mediaQuery.mobileOnlyEscapeHatch} {
    position: fixed;
    margin-top: ${(props) => (props.isLimited ? '70px' : '130px')};
    top: ${(props) => (props.isMobileApp ? '90px' : '68px')};
    left: ${(props) => (props.isMobileApp ? '5%' : 0)};
    right: ${(props) => (props.isMobileApp ? '5%' : 0)};
    border-bottom: 1px solid ${themeGet('colors.greys.alto')};
    width: ${(props) => (props.isMobileApp ? '90%' : '100%')};
  }
`;

CalendarKeyWrapper.displayName = 'CalendarKeyWrapper';

const CalendarKey = ({ weekdayNames, month, year, isLimited, ...rest }) => {
  const isMobileApp = useSelector(getIsMobileApp);
  return (
    <CalendarKeyWrapper {...rest} isMobileApp={isMobileApp} isLimited={isLimited}>
      {weekdayNames.map((weekday, index) => (
        <Box width={1 / 7} key={`${month}${year}${weekday}${index}`} data-testid="weekday-box">
          <Text>{weekday}</Text>
        </Box>
      ))}
    </CalendarKeyWrapper>
  );
};

CalendarKey.propTypes = {
  weekdayNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  isLimited: PropTypes.bool,
};

export default CalendarKey;

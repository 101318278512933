import React, { useRef, useEffect, useState, useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import Header from './Header';
import Footer from './Footer';
import { Hide, Box } from '@qga/roo-ui/components';
import FocusManager from 'components/FocusManager';
import { isIOSDevice, fixBodyScroll, unfixBodyScroll, isPhone } from 'lib/browser';
import { ANIMATION_DURATION } from './constants';
import { useTransition } from 'hooks/useTransition';
import { ResponsiveContainer, Body, Inner } from './primitives';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { mediaQuery } from 'lib/styledSystem';
import { getPageName } from 'store/router/routerSelectors';

interface ResponsiveModalProps {
  title: string;
  children: (props: {
    isOpen: boolean;
    openModal: () => void;
    submitModal: React.MouseEventHandler<HTMLElement>;
    blurModal: React.MouseEventHandler<HTMLElement>;
    cancelModal: () => void;
  }) => React.ReactNode;
  onOpenModal?: () => void;
  onSubmit: (value: Event) => void;
  onCancel: () => void;
  onBlur: (value: Event) => void;
  submitButtonText?: string;
  enableHeader: boolean;
  enableFooter: boolean;
  isSubmitOnCancel: boolean;
  backgroundColor: string[];
  enableScroll?: boolean;
  closeOnUpdate?: boolean;
  setOpenRecentSearches?: (value: boolean) => void;
}

const MobileAppBackground = styled(Box)`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(34, 34, 34, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${themeGet('zIndices.modal')};

  ${mediaQuery.minWidth.sm} {
    display: none;
  }
`;

const ResponsiveModal = React.memo(
  ({
    title = '',
    children,
    onOpenModal = noop,
    onSubmit = noop,
    onCancel = noop,
    onBlur = noop,
    closeOnUpdate = true,
    submitButtonText = undefined,
    enableHeader = true,
    enableFooter = true,
    enableScroll = true,
    isSubmitOnCancel = false,
    setOpenRecentSearches = noop,
  }: ResponsiveModalProps) => {
    const scrollRef = useRef<HTMLInputElement>(null);
    const [transitionToOpen, setTransitionToOpen] = useState(false);
    const transitionState = useTransition({ transition: transitionToOpen, duration: ANIMATION_DURATION });
    const pageName = useSelector(getPageName);
    const isProperty = ['property', 'exclusive-offer'].includes(pageName ?? '');
    const getBgColor = isProperty ? 'greys.porcelain' : 'white';

    const isMobileApp = useSelector(getIsMobileApp);
    const isMobileAppEntered = isMobileApp && transitionState === 'entered';

    // NOTE: we derive isOpen here, so children do not render one frame before the container
    const isOpen = transitionState !== 'exited';

    const displayFooter = enableFooter && (onSubmit !== noop || submitButtonText);

    useEffect(() => {
      if (transitionState === 'entering') {
        if (isFunction(onOpenModal)) onOpenModal();
        if (isPhone()) fixBodyScroll();
        // scroll to force the modal to lock into it's fixed position if a field is focused after the keyboard is opened
        // 100px ensures that we get past the address bar if it wasn't active and gets forced into view
        if (isPhone() && isIOSDevice()) window.scrollBy(0, -100);
      }

      if (transitionState === 'exiting') {
        if (isPhone() && scrollRef.current) {
          scrollRef.current.scrollTop = 0;
        }
      }

      if (transitionState === 'exited') {
        if (isPhone()) unfixBodyScroll();
      }
    }, [onOpenModal, transitionState]);

    useEffect(() => {
      return () => unfixBodyScroll();
    }, []);

    const openModal = useCallback(() => {
      setTransitionToOpen(true);
    }, []);

    const closeModal = useCallback(() => {
      setTransitionToOpen(false);
      setOpenRecentSearches(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //disabled <FocusManager> if need be
    const blurModal = useCallback(
      (event) => {
        if (!transitionToOpen) return;
        if (closeOnUpdate) {
          closeModal();
        }
        onBlur(event);
      },
      [closeModal, onBlur, transitionToOpen, closeOnUpdate],
    );

    const submitModal = useCallback(
      (event) => {
        if (closeOnUpdate) {
          closeModal();
        }
        onSubmit(event);
      },
      [closeModal, onSubmit, closeOnUpdate],
    );

    const cancelModal = useCallback(() => {
      closeModal();
      onCancel();
    }, [closeModal, onCancel]);

    const inputElement = typeof window === 'object' ? (document?.activeElement as HTMLInputElement | null) : null;

    useEffect(() => {
      const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key !== 'Escape') return;
        if (inputElement) inputElement.blur();
        cancelModal();
      };
      document.addEventListener('keyup', handleKeyUp);

      return () => {
        document.removeEventListener('keyup', handleKeyUp);
      };
    }, [cancelModal, inputElement]);

    return (
      <Fragment>
        {isMobileAppEntered && <MobileAppBackground />}
        <ResponsiveContainer backgroundColor={['white', 'greys.porcelain']} transitionState={transitionState} isMobileApp={isMobileApp}>
          <FocusManager onBlur={blurModal} onClickOutside={submitModal}>
            {enableHeader && (
              <Hide sm md lg>
                <Header title={title} transitionState={transitionState} cancelModal={isSubmitOnCancel ? submitModal : cancelModal} />
              </Hide>
            )}
            <Body
              transitionState={transitionState}
              ref={scrollRef}
              enableHeader={enableHeader}
              enableFooter={enableFooter}
              enableScroll={enableScroll}
            >
              <Inner
                transitionState={transitionState}
                data-testid="modal-content"
                data-transition-state={transitionState}
                backgroundColor={getBgColor}
              >
                {children({ isOpen, openModal, submitModal, blurModal, cancelModal })}
              </Inner>
            </Body>
            {displayFooter && (
              <Hide sm md lg>
                <Footer
                  buttonText={submitButtonText}
                  isMobileApp={isMobileApp}
                  submitModal={submitModal}
                  transitionState={transitionState}
                />
              </Hide>
            )}
          </FocusManager>
        </ResponsiveContainer>
      </Fragment>
    );
  },
);

ResponsiveModal.displayName = 'ResponsiveModal';

export default ResponsiveModal;

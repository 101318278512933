import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import { CalendarState } from './calendarReducer';

type StoreState = { calendar: CalendarState };

export const getCalendar = (state: StoreState) => state?.calendar?.calendar;

export const getCalendarStays = createSelector(getCalendar, (calendar) => {
  const sortedStays = sortBy(calendar?.stays || [], ({ date }) => new Date(date)); // availability must be returned in chronological order
  return sortedStays;
});

import get from 'lodash/get';

export const getMinStay = ({ exclusiveOfferRoomTypes, availabilityStays, startAvail, roomTypeId }) => {
  const roomTypesWithMinStay = exclusiveOfferRoomTypes.filter((offer) => typeof offer.minNumberOfNights === 'number');
  const minStayInt = roomTypesWithMinStay.map((r) => r.minNumberOfNights);
  const minStartAvail = get(availabilityStays[startAvail], 'minStay');
  const minimumMinStay = minStayInt.length > 0 ? Math.min(...minStayInt) : minStartAvail;

  if (roomTypeId) {
    const roomTypeIdMinStay = exclusiveOfferRoomTypes.filter((offer) => offer.id === roomTypeId);
    const roomMinStay = parseInt(roomTypeIdMinStay.map((r) => r.minNumberOfNights));
    const roomMinStayInt = isNaN(roomMinStay) ? minimumMinStay : roomMinStay;

    return roomMinStayInt ?? 0;
  } else {
    return minimumMinStay ?? 0;
  }
};

import { themeGet, backgroundColor } from 'styled-system';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';
import includes from 'lodash/includes';
import { Box } from '@qga/roo-ui/components';
import { mediaQuery } from 'lib/styledSystem';
import { HEADER, FOOTER, ANIMATION_DURATION } from './constants';

const fadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

export const ResponsiveContainer = styled(Box)`
  background-color: transparent;

  ${mediaQuery.mobileOnlyEscapeHatch} {
    ${backgroundColor};
    ${(props) =>
      includes(['entering', 'entered', 'exiting'], props.transitionState) &&
      css`
        padding: ${props.isMobileApp ? '10px' : themeGet('space.4')(props)};
        z-index: ${themeGet('zIndices.modal')(props)};
        position: fixed;
        top: ${props.isMobileApp ? '5%' : 0};
        left: ${props.isMobileApp ? '5%' : 0};
        right: ${props.isMobileApp ? '5%' : 0};
        bottom: ${props.isMobileApp ? '5%' : 0};
      `};
  }
`;

export const Body = styled(Box)`
  ${mediaQuery.mobileOnlyEscapeHatch} {
    ${({ transitionState, enableHeader, enableFooter, enableScroll }) =>
      includes(['entering', 'entered', 'exiting'], transitionState) &&
      css`
        overflow: ${enableScroll ? 'scroll' : 'hidden'};
        -webkit-overflow-scrolling: touch;
        position: absolute;
        left: 0;
        right: 0;
        top: ${enableHeader ? HEADER : 0}px;
        bottom: ${enableFooter ? FOOTER : 0}px;
        margin-top: 0.5rem;
      `};

    ${({ transitionState }) =>
      includes(['entering', 'entered'], transitionState) &&
      css`
        animation: ${fadeIn} ${ANIMATION_DURATION}ms ease-in forwards;
      `};

    ${({ transitionState }) =>
      includes(['exiting'], transitionState) &&
      css`
        animation: ${fadeIn} ${ANIMATION_DURATION}ms ease-in reverse forwards;
      `};
  }
`;

export const Inner = styled(Box)`
  ${mediaQuery.mobileOnlyEscapeHatch} {
    ${(props) =>
      includes(['entering', 'entered', 'exiting'], props.transitionState) &&
      css`
        padding: ${themeGet('space.4')(props)};
      `};
  }
`;

import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { mediaQuery } from 'lib/styledSystem';
import { NakedButton, Box, Flex } from '@qga/roo-ui/components';

export const MinStayMessageWrapper = styled(Flex)`
  background-color: ${themeGet('colors.white')};
  width: max-content;
  padding: ${themeGet('space.2')};
  box-shadow: ${themeGet('shadows.tooltip')};
  margin-bottom: ${themeGet('space.3')};

  ${mediaQuery.minWidth.sm} {
    z-index: ${themeGet('zIndices.fullscreenGallery')};
  }

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:after {
      content: ' ';
      position: absolute;
      top: -25px;
      left: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: white;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-popper-placement^='top'] > .arrow {
    bottom: -30px;
    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const DayWrapper = styled(Box)`
  flex: 1 1 auto;
  width: calc(100% / 7);
  margin: 0 -1px -1px 0;
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

DayWrapper.defaultProps = {
  ...Box.defaultProps,
};

DayWrapper.displayName = 'DayWrapper';

export const Button = styled(NakedButton)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: ${themeGet('colors.greys.charcoal')};
  padding: 0;
  border: ${themeGet('borders.1')} transparent;
  border-radius: ${themeGet('radii.default')};
  font-weight: ${themeGet('fontWeights.bold')};

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${themeGet('colors.greys.dusty')};
    text-decoration: line-through;
  }

  ${(props) =>
    props.selected &&
    !props.highlighted &&
    css`
      background-color: ${themeGet('colors.lightBlue')(props)};
      border-radius: 0;

      &:hover,
      &:focus {
        border-color: ${themeGet('colors.brand.secondary')(props)};
        z-index: 1;
      }
    `};

  ${(props) =>
    props.highlighted &&
    !props.selected &&
    css`
      background-color: ${themeGet('colors.lightBlue')(props)};
      border-radius: 0;

      &:hover,
      &:focus {
        border-color: ${themeGet('colors.brand.secondary')(props)};
        z-index: 1;
      }
    `};

  ${(props) =>
    props.highlighted &&
    props.selected &&
    css`
      background-color: ${themeGet('colors.brand.secondary')(props)};

      &:hover,
      &:focus {
        border-color: ${themeGet('colors.brand.secondary')(props)};
      }
    `};

  ${(props) =>
    props.selectable &&
    !props.selected &&
    css`
      &:hover,
      &:focus {
        border-color: ${themeGet('colors.brand.secondary')(props)};
      }
    `};

  ${(props) =>
    !props.selectable &&
    props.selected &&
    props.highlighted &&
    props.disabled &&
    css`
      background-color: ${themeGet('colors.brand.secondary')(props)};

      &:active {
        background-color: ${themeGet('colors.brand.secondary')(props)};
      }
      &:hover,
      &:focus {
        border-color: ${themeGet('colors.brand.secondary')(props)};
      }
      &:disabled {
        cursor: pointer;
        color: inherit;
        opacity: 1;
        text-decoration: none;
      }
      &:hover:disabled,
      &:focus:disabled {
        background-color: ${themeGet('colors.brand.secondary')(props)};
      }
    `};

  ${(props) =>
    !props.selectable &&
    !props.disabled &&
    css`
      background-color: ${themeGet('colors.greys.alto')(props)};

      &:hover,
      &:focus {
        background-color: ${themeGet('colors.greys.dusty')(props)};
      }
    `};

  ${(props) =>
    !props.isMinStay &&
    !props.selected &&
    props.disabled &&
    css`
      &:disabled {
        text-decoration: line-through;
      }
      &:hover,
      &:focus {
        background-color: ${themeGet('colors.greys.dusty')(props)};
        border-color: ${themeGet('colors.brand.secondary')(props)};
      }
    `};

  ${(props) =>
    !props.isMinStay &&
    !props.selected &&
    props.highlighted &&
    props.disabled &&
    css`
      &:disabled {
        cursor: not-allowed;
        text-decoration: none;
        color: ${themeGet('colors.greys.charcoal')(props)};
      }
      background-color: ${themeGet('colors.lightBlue')(props)};
      border-radius: 0;

      &:hover:disabled,
      &:focus:disabled {
        background-color: ${themeGet('colors.lightBlue')(props)};
      }
    `};
`;

Button.displayName = 'Button';
Button.defaultProps = {
  ...NakedButton.defaultProps,
  disabled: PropTypes.bool,
};

import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { mediaQuery } from 'lib/styledSystem';
import { NakedButton, Button, Flex } from '@qga/roo-ui/components';

export const StaySummaryWrapper = styled(Flex)`
  position: fixed;
  flex-direction: column;
  padding-bottom: ${themeGet('space.4')};
  justify-content: space-between;
  width: 100%;
  height: 100px;
  z-index: ${themeGet('zIndices.phoneMenuDrawer')};
  background-color: white;
  ${mediaQuery.minWidth.sm} {
    margin-top: ${themeGet('space.4')};
    position: relative;
    flex-direction: row;
    height: auto;
  }
`;

export const FilterWrapper = styled(Flex)`
  padding-right: ${themeGet('space.8')};
  margin-left: ${themeGet('space.2')};
  justify-content: flex-start;
  background-color: white;
  padding: ${themeGet('space.3')} 0;
  ${mediaQuery.minWidth.sm} {
    padding: 0;
    margin-top: 0;
    height: auto;
    width: 50%;
    position: relative;
    justify-content: flex-start;
  }
`;

export const Footer = styled(Flex)`
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid ${themeGet('colors.greys.alto')};
  border-bottom: 1px solid ${themeGet('colors.greys.alto')};
  background: ${themeGet('colors.white')};

  ${mediaQuery.minWidth.sm} {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    padding: ${themeGet('space.5')} ${themeGet('space.8')};
    border-top: 1px solid ${themeGet('colors.greys.alto')};
  }
`;

export const StickyHeader = styled(Flex)`
  flex-direction: column;
  background-color: ${themeGet('colors.white')};
  z-index: ${(props) => (props.showMenu ? themeGet('zIndices.modal')(props) : themeGet('zIndices.mobileModal')(props))};
  padding: ${themeGet('space.2')} ${themeGet('space.0')};

  ${mediaQuery.minWidth.sm} {
    align-items: center;
    flex-direction: row;
    position: relative;
    background-color: transparent;
    padding: ${themeGet('space.6')} ${themeGet('space.8')};
    justify-content: space-between;
  }

  ${mediaQuery.mobileOnlyEscapeHatch} {
    padding: ${themeGet('space.1')} ${themeGet('space.0')};
    position: fixed;
    height: ${(props) => (props.isLimited ? '70px' : '162px')};
    top: 68px;
    right: ${themeGet('space.4')};
    left: 1px;
    border-top: 1px solid ${themeGet('colors.greys.alto')};
  }

  ${(props) =>
    props.isMobileApp &&
    css`
      margin: 30px 5% 0;
    `};
`;

export const StickyFooter = styled(Flex)`
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid ${themeGet('colors.greys.alto')};
  background: ${themeGet('colors.white')};
  z-index: ${themeGet('zIndices.modalContent')};

  ${mediaQuery.minWidth.sm} {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    padding: ${themeGet('space.3')} ${themeGet('space.8')};
    border-top: 1px solid ${themeGet('colors.greys.alto')};
    background: transparent;
    z-index: ${themeGet('zIndices.modalContent')};
  }
`;

export const CancelButton = styled(NakedButton)`
  font-size: ${themeGet('fontSizes.sm')};
  line-height: ${themeGet('lineHeights.loose')};
  font-weight: ${themeGet('fontWeights.bold')};
  text-transform: uppercase;
  color: ${themeGet('colors.brand.primary')};
  letter-spacing: 1.5px;
  padding: ${themeGet('space.3')} 0;
`;

export const Actions = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${themeGet('colors.greys.alto')};

  ${mediaQuery.minWidth.sm} {
    position: relative;
    border: none;
  }

  ${mediaQuery.mobileOnlyEscapeHatch} {
    padding: ${themeGet('space.4')};
  }

  ${(props) =>
    props.isMobileApp &&
    css`
      position: fixed;
      bottom: 5%;
      left: 5%;
      right: 5%;
      background-color: white;
    `};
`;

export const ConfirmButton = styled(Button)`
  border: none;
  font-size: ${themeGet('fontSizes.sm')};
  line-height: ${themeGet('lineHeights.loose')};
  letter-spacing: 1.5px;
  padding: ${themeGet('space.3')} ${themeGet('space.8')};
`;

interface FocusProps {
  NONE: string;
  START_DATE: string;
  END_DATE: string;
  DATE: string;
}

export const FOCUS: FocusProps = Object.freeze({
  NONE: 'NONE',
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
  DATE: 'DATE',
});

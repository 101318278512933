import React from 'react';
import PropTypes from 'prop-types';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Flex, Button } from '@qga/roo-ui/components';
import includes from 'lodash/includes';
import { FOOTER, ANIMATION_DURATION } from './constants';

const PhoneOnlyFooter = styled(Flex)`
  padding: ${themeGet('space.2')};
  box-shadow: ${themeGet('shadows.footer')};
  background: ${themeGet('colors.white')};
  height: ${FOOTER}px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  opacity: 0;
  transition: opacity ${ANIMATION_DURATION}ms ease-in;

  ${({ transitionState }) =>
    includes(['entering', 'entered'], transitionState) &&
    css`
      opacity: 1;
    `};

  ${({ transitionState }) =>
    transitionState === 'exited' &&
    css`
      display: none;
    `};

  ${({ isMobileApp, transitionState }) =>
    includes(['entering', 'entered'], transitionState) &&
    isMobileApp &&
    css`
      bottom: 5%;
      left: 5%;
      right: 5%;
    `};
`;

PhoneOnlyFooter.displayName = 'PhoneOnlyFooter';

const Footer = ({ buttonText, isMobileApp = false, submitModal, transitionState }) => (
  <PhoneOnlyFooter transitionState={transitionState} isMobileApp={isMobileApp}>
    <Button variant="primary" block onClick={submitModal} data-testid="apply-button">
      {buttonText}
    </Button>
  </PhoneOnlyFooter>
);

Footer.propTypes = {
  buttonText: PropTypes.string,
  isMobileApp: PropTypes.bool,
  submitModal: PropTypes.func.isRequired,
  transitionState: PropTypes.string.isRequired,
};

Footer.defaultProps = {
  buttonText: 'Apply',
};

export default Footer;
